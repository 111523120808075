/** @format */

import React, { useEffect, useState } from "react";
import { MainLayout } from "component/layout/MainLayout";
import { Blogs } from "./components/Blogs";
import { Search } from "react-feather";
import { usePosts } from "./api/getPosts";
import { Link } from "react-router-dom";
import BlogPhoto from "assets/blogHeader.svg";
import { PageHeader } from "component/layout/components/PageHeader";
import Header from "assets/header.svg";

export const Blog = () => {
  const postsQuery = usePosts();
  const [activeFilter, setActiveFilter] = useState("Latest");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="blog">
      <div className="page-header">
        <PageHeader image={Header} title="Blog">
          <div>
            <p className="font-bold dark:text-black leading-[4rem]">
              Join our <span className="text-red-500">P7 </span>Vacists
              <span className="text-red-500"> Tech Mentorship</span> Program
              this <br />
              <span className="text-red-500"> 21st </span>November 2022
            </p>
            <div className="my-8">
              <a
                href="https://forms.gle/17C8hhXJ1nbhxcTM6"
                target="_blank"
                rel="noreferrer"
              >
                <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
                  Join here
                </button>
              </a>
            </div>
          </div>
          {/* <div className="space-y-8">
            <div className="font-bold dark:text-black space-y-6">
              <p>
                Join <span className="text-red-500">Codingal</span> today,
              </p>
              <p>A place where kids</p>
              <p>Love and Learn</p>
              <p>How to Code.</p>
            </div>
            <div>
              <a
                href="https://forms.gle/H3sKLVQhPL3sVntMA"
                target="_blank"
                rel="noreferrer"
              >
                <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
                  Join here
                </button>
              </a>
            </div>
          </div> */}
        </PageHeader>
      </div>
      <div className="px-8 lg:px-32 dark:text-white bg-components">
        <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
          {/* Filter section */}
          <div className="flex flex-col md:flex-row justify-between space-y-8">
            <div className="w-full md:w-7/12 flex space-x-4">
              <button
                className={
                  activeFilter === "Latest"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Latest")}
              >
                Latest Articles
              </button>
              <button
                className={
                  activeFilter === "All"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("All")}
              >
                All
              </button>
              <button
                className={
                  activeFilter === "Social Media"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Social Media")}
              >
                Social Media
              </button>
              <button
                className={
                  activeFilter === "Archives"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Archives")}
              >
                Archives
              </button>
            </div>
            <div className="w-full md:w-4/12 flex space-x-4">
              <input
                placeholder="Search for blog here ..."
                className="p-2 w-full rounded-lg border border-black dark:bg-slate-500 dark:placeholder:text-gray-300"
              />
              <button className="bg-slate-900 rounded-lg p-2 text-white">
                <Search />
              </button>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between space-y-8">
            {/* Blog post section */}
            <div className="w-full md:w-7/12">
              {postsQuery.data?.map((post, index) => (
                <Blogs key={index} {...post} />
              ))}
            </div>
            {/* Recent posts section */}
            <div className="w-full md:w-4/12">
              <div className="w-full">
                <div className="space-y-2 ">
                  <p className="rounded-t-lg px-4 py-2 bg-slate-900 text-white text-center font-bold text-lg">
                    Recent Posts
                  </p>
                  <ul className="space-y-2">
                    {postsQuery.data?.slice(0, 4).map((post, index) => (
                      <li
                        key={index}
                        className=" px-4 py-2 bg-white dark:bg-slate-500 shadow-lg"
                      >
                        <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
