import { useQuery } from "react-query";
import { posts } from "server/posts";

export const getPosts = () => {
  return posts;
};

export const usePosts = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["posts"],
    queryFn: () => getPosts(),
  });
};
