import { PageHeader } from "component/layout/components/PageHeader";
import { MainLayout } from "component/layout/MainLayout";
import AboutHeader from "assets/aboutHeader.svg";
import { useEffect } from "react";
import { Team } from "./components/Team";
import { Headings } from "component/Text/Headings";
import { Contact } from "./components/Contact";

export const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="about">
      <div className="page-header">
        <PageHeader image={AboutHeader} title="About" />
      </div>
      <div className="px-8 lg:px-32 dark:text-white bg-components">
        <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
          <div className="space-y-10">
            <Headings>Who we are</Headings>
            <p className="text-lg">
              CodeImpact LTD is a Software development and training consultancy
              that designs and develops curricula for coding boot-camps in
              accelerated learning environments. CodeImpact builds bespoke
              software for clients on demand and also runs an open developer
              community that aims to mentor young budding software developers to
              become not only technically competent but also grow as technology
              leaders.
            </p>
          </div>
          <div className="space-y-10">
            <Headings>The Team</Headings>
            <Team />
            <Headings>Get in touch with us</Headings>
            <Contact />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
