import Outbox from "../../../assets/outbox.png";
import TungaLogo from "../../../assets/tungaLogo.png";
import Andela from "../../../assets/andela.png";
import Talent from "../../../assets/talentLogo.png";
import Kanzu from "../../../assets/kanzuLogo.png";
import { useState } from "react";

export const Experience = () => {
  //set initial state for active partner button
  const [isActive, setActive] = useState({
    name: "Andela",
    site_link: "https://andela.com/",
    img_url: Andela,
    content:
      "Our team has experience working with Andela as technical curriculum developers in the learning and development space",
  });

  const PartnerDetails = [
    {
      name: "Outbox",
      site_link: "https://outbox.co.ug/",
      img_url: Outbox,
      content:
        "Build technical and soft skills curricula and facilitated the  delivery of the lessons and assessments",
    },
    {
      name: "Tunga",
      site_link: "https://tunga.io/",
      img_url: TungaLogo,
      content:
        "Building content and technical learning road maps for the Tunga learning platform ",
    },
    {
      name: "Andela",
      site_link: "https://andela.com/",
      img_url: Andela,
      content:
        "Our team has experience working with Andela as technical curriculum developers in the learning and development space",
    },
    {
      name: "Talent",
      site_link: "https://tcentric.com/",
      img_url: Talent,
      content:
        "Our team has experience working with Andela as technical curriculum developers in the learning and development space",
    },
    {
      name: "KanzuCode",
      site_link: "https://kanzucode.com/",
      img_url: Kanzu,
      content:
        "We train software engineers and integrate them into tech teams for companies. Our tutors are well equipped to deliver the lessons and assess the learners",
    },
  ];

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col flex-wrap space-y-8 space-x-8 md:flex-row justify-evenly items-center">
        {PartnerDetails.map((partner, index) => {
          return (
            <a
              key={index}
              href={partner.site_link}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setActive(partner)}
              onMouseLeave={() => setActive(partner)}
              className={
                isActive.name === partner.name
                  ? "scale-110 duration-300 transition ease-in-out"
                  : "scale-75 duration-300 transition ease-in-out"
              }
            >
              <img
                src={partner.img_url}
                alt="..."
                className="max-h-16"
                loading="lazy"
              />
            </a>
          );
        })}
      </div>
      <div className="w-4/6 mx-auto py-8 hidden lg:flex ">
        <p className="text-center text-white text-lg duration-700 delay-500 transition ease-in-out">
          {isActive.content}
        </p>
      </div>
    </div>
  );
};
