import {
  ArrowRight,
  Facebook,
  Linkedin,
  Mail,
  Phone,
  Twitter,
  Youtube,
} from "react-feather";
import { Link } from "react-router-dom";
import { HeadFooter } from "./HeadFooter";
import JTlogo from "../../../assets/JTLogo.png";

export const NewFooter = () => {
  return (
    <div className="bg-gray-900 py-8 px-8 lg:px-32 text-white text-sm">
      <div className="border-t border-white space-y-8 py-8">
        <div className="flex flex-col md:flex-row space-y-8 justify-between">
          {/* <!--footer--> */}
          <div className="w-full md:w-1/3">
            <div className="">
              {/* <HeadFooter>Cohorts</HeadFooter> */}
              <div className="space-y-4">
                <p className="">Join our coding programs</p>
                <a
                  href="https://forms.gle/H3sKLVQhPL3sVntMA"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-black lg:w-full max-w-lg px-6 flex text-center py-2 rounded"
                >
                  <span className="mr-2">Get started</span>
                  <ArrowRight />
                </a>
              </div>
            </div>
          </div>
          <div className="w-auto">
            {/* <HeadFooter>Social media</HeadFooter> */}
            <div className="flex space-x-4 text-white text-xl">
              <a href="https://www.facebook.com/codeimpactug/">
                <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                  <Facebook className="w-4" />
                </div>
              </a>
              <a href="https://twitter.com/CodeImpact_ug">
                <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                  <Twitter className="w-4" />
                </div>
              </a>
              <a href="https://www.youtube.com/channel/UCxyFbQUHwsxF1vD2D7cO1sw">
                <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                  <Youtube className="w-4" />
                </div>
              </a>
              <a href="/">
                <div
                  className="bg-white px-2 py-1 rounded-full text-black hover:text-white 
              hover:bg-gray-700"
                  title="alex.nkuzi@codeimpact.co"
                >
                  <Mail className="w-4" />
                </div>
              </a>
              <a href="/">
                <div
                  className="bg-white px-2 py-1 rounded-full text-black hover:text-white 
              hover:bg-gray-700"
                  title="+256-772-810410"
                >
                  <Phone className="w-4" />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/codeimpactug/">
                <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                  <Linkedin className="w-4" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="text-center space-y-4">
          <p>&copy; 2022 CodeImpact All Rights Reserved</p>
          <div className="flex justify-center">
            <p>Powered by: </p>
            <img src={JTlogo} alt="logo" className="w-12" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
};
