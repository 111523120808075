import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import bulbIcon from "assets/fi-br-bulb.png";
import calendarIcon from "assets/fi-br-calendar.png";

export const Activities = () => {
  const ActivityList = [
    {
      title: "Skill up sessions",
      icon: bulbIcon,
      bgColor: "bg-orange-200",
      content:
        "Held on a weekly basis where everyone shares their learning progress, blockers faced as they engage with the coding challenges, and the community leads help out on solving these.",
    },
    {
      title: "Webinar Series",
      icon: calendarIcon,
      bgColor: "bg-sky-200",
      content:
        "Here we invite influential experts in the tech space to share with the community about a number of topics which are related to tech.",
    },
    {
      title: "Technical Writing",
      icon: bulbIcon,
      bgColor: "bg-slate-200",
      content:
        "We also conduct these sessions to equip our community members with this skill so as to enable them to write better documentations.",
    },
  ];

  return (
    <div className="">
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        centeredSlides={true}
        className="mySwiper"
      >
        {ActivityList.map((item, index) => (
          <SwiperSlide key={index} className="pb-14">
            <div
              className="bg-white shadow-lg rounded-lg p-10 w-full flex relative overflow-hidden
            h-[28rem] md:h-[18rem]"
            >
              <div
                className={`absolute top-0 right-0 w-8 h-8 ${item.bgColor}`}
              ></div>
              <div
                className={`absolute top-0 right-0 h-16 w-16 rounded-full rotate-45
                  ${item.bgColor}`}
              >
                <div className="-rotate-45 p-5">
                  <img
                    src={item.icon}
                    alt="..."
                    className="w-6"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="flex flex-col-reverse pt-20">
                <div>
                  <p className="my-4 font-bold text-xl">{item.title}</p>
                  <p className="my-4">{item.content}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
