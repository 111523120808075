import { Headings } from "component/Text/Headings";
import Certificate from "../../../assets/certificate.png";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";

export const Achievements = () => {
  //choose the screen size to determine number of slides displayed in carousel
  const [slides, isSlides] = useState(2);
  const handleResize = () => {
    if (window.innerWidth < 770) {
      return isSlides(1);
    } else {
      return isSlides(2);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });
  const certificateList = [
    {
      image_url: Certificate,
    },
    {
      image_url: Certificate,
    },
    {
      image_url: Certificate,
    },
    {
      image_url: Certificate,
    },
    {
      image_url: Certificate,
    },
  ];

  return (
    <div className="space-y-10">
      <Headings>Our Achievements</Headings>
      <p className="">
        Here are some of the Freecodecamp certificates of some of our Community
        Members
      </p>
      <Swiper
        slidesPerView={slides}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {certificateList.map((item, index) => (
          <SwiperSlide key={index} className="pb-14">
            <div className="bg-white">
              <img
                src={item.image_url}
                alt="..."
                className="bg-white shadow-lg"
                loading="lazy"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
