import { MainLayout } from "component/layout/MainLayout";
import { Spinner } from "component/Spinner";
import { useParams } from "react-router-dom";
import { usePost } from "./api/getPost";
import { NewsLetter } from "./components/NewsLetter";
import { useEffect } from "react";

export const BlogPost = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { slug } = useParams();
  const postQuery = usePost({ slug });

  if (postQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postQuery) return null;

  return (
    <>
      <MainLayout page="blogPost">
        <div className="px-8 lg:px-32 lg:pt-16 bg-components dark:text-white">
          {/* Title and Profile */}
          <div className="py-12">
            <div className="justify-center text-center space-y-10 my-8">
              <div className="space-y-6 pb-8">
                <img
                  src={postQuery.data.image_url}
                  alt="..."
                  className="rounded-full w-32 h-32 flex bg-black mx-auto"
                  loading="lazy"
                />
                <p className="text-xl font-semibold uppercase">
                  {postQuery.data.author}
                </p>
              </div>
              <p className="text-lg">{postQuery.data.date}</p>
              <p className="text-4xl font-extrabold w-full md:w-2/3 mx-auto">
                {postQuery.data.title}
              </p>
            </div>
            <div
              className="text-xl text-justify tracking-widest leading-[4rem] w-3/4 mx-auto"
              dangerouslySetInnerHTML={{ __html: postQuery.data.desc }}
            />
            {/* <div className="my-8">
              <NewsLetter author={postQuery.data.author} />
            </div> */}
          </div>
        </div>
      </MainLayout>
    </>
  );
};
