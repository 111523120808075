export const Card = ({ img_url, name, post, content }) => {
  return (
    <div className="shadow-lg rounded dark:bg-slate-500 p-10 bg-white space-y-6">
      <p className="text-left">{content}</p>
      <div className="flex p-2 space-x-6 items-center">
        <img
          src={img_url}
          alt="..."
          className="rounded-full w-20 outline outline-offset-2 outline-8 text-sky-800"
          loading="lazy"
        />
        <div className="flex flex-col text-left">
          <p className="font-bold text-xl">{name}</p>
          <p className="">{post}</p>
        </div>
      </div>
    </div>
  );
};
