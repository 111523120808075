import BeneficiaryImage from "../../../assets/team2.png";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "component/Card/Card";

export const BeneficiariesStory = () => {
  const BeneficiaryList = [
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: BeneficiaryImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: BeneficiaryImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: BeneficiaryImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: BeneficiaryImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
  ];
  return (
    <div className="space-y-10">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {BeneficiaryList.map((item, index) => (
          <SwiperSlide key={index} className="pb-14">
            <Card
              content={item.content}
              name={item.name}
              post={item.cohort}
              img_url={item.image_url}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
