import React from "react";
import { Link } from "react-router-dom";

export const Blogs = ({ image_url, date, title, desc, author, slug }) => {
  return (
    <>
      <div className="rounded-lg w-full bg-white shadow-lg dark:bg-slate-500 mb-20">
        <div className="relative">
          <img
            src={image_url}
            alt="posts"
            className="w-full h-80"
            loading="lazy"
          />
          <div className="absolute bottom-5 left-5 space-x-2 flex text-white ">
            <div className="bg-white w-0.5 h-6"></div>
            <p className="font-black text-xl">{date}</p>
          </div>
        </div>
        <div className="space-y-2 my-2 p-4">
          <p className="font-bold">{title}</p>
          <div className="space-y-4 space-x-4 flex flex-wrap text-sm items-center w-full justify-between">
            <div>
              <p className="font-bold">{author}</p>
            </div>
            <Link to={`/blog/${slug}`}>
              <button className="px-3 py-2 text-white bg-slate-900 rounded">
                Read more
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
