import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Hamburger from "hamburger-react";

export const NavBar = ({ page }) => {
  const [navBg, setNavBg] = useState("");
  const [open, setOpen] = useState(false);
  const size = "17rem"; //width for drawer

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavBg("bg-white");
    } else {
      setNavBg("");
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div className="">
      <div className={`fixed ${navBg} z-10 w-full`}>
        <div className="">
          <nav className="">
            <div className="items-center flex justify-between px-8 lg:px-32 my-4 dark:text-gray-800">
              <div>
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="hidden lg:flex">
                <Menu page={page} />
              </div>
              <div className="lg:hidden flex align-right">
                <Hamburger toggled={open} toggle={setOpen} />
                {/* Dropdown */}
                <Drawer
                  open={open}
                  onClose={() => {
                    setOpen((prevState) => !prevState);
                  }}
                  direction="left"
                  style={{ backgroundColor: "white", width: `${size}` }}
                >
                  <div className="p-5 space-y-4">
                    <Menu page={page} />
                  </div>
                </Drawer>
              </div>
            </div>
            {/* View for small phones */}
          </nav>
        </div>
      </div>
    </div>
  );
};

const Menu = ({ page }) => {
  return (
    <>
      <div className="p-5 hover:font-bold">
        <Link to="/" className={page === "home" ? "font-bold" : ""}>
          Home
        </Link>
      </div>
      {/* <div className="p-5 hover:font-bold">
        <Link to="/about" className={page === "about" ? "font-bold" : ""}>
          About
        </Link>
      </div>
      <div className="p-5 hover:font-bold">
        <Link to="/gallery" className={page === "gallery" ? "font-bold" : ""}>
          Gallery
        </Link>
      </div> */}
      <div className="p-5 hover:font-bold">
        <Link to="/blog" className={page === "blog" ? "font-bold" : ""}>
          Blog
        </Link>
      </div>
      {/* <div className="p-5 hover:font-bold">
        <Link
          to="/community"
          className={page === "community" ? "font-bold" : ""}
        >
          Community
        </Link>
      </div>
      <div className="p-5 hover:font-bold">
        <Link to="/cohort" className={page === "cohort" ? "font-bold" : ""}>
          Cohort
        </Link>
      </div>
      <div className="p-5 hover:font-bold">
        <Link
          to="/tech-kids"
          className={page === "tech-kids" ? "font-bold" : ""}
        >
          Tech Kids
        </Link>
      </div> */}
    </>
  );
};
