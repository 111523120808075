import { Headings } from "component/Text/Headings";
import AlumniImage from "../../../assets/team2.png";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "component/Card/Card";

export const Alumni = () => {
  const AlumniList = [
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: AlumniImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: AlumniImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: AlumniImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis",
    },
  ];
  return (
    <div className="space-y-10">
      <Headings>Our Alumni</Headings>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {AlumniList.map((item, index) => (
          <SwiperSlide key={index} className="pb-14">
            <Card
              name={item.name}
              content={item.content}
              post={item.cohort}
              img_url={item.image_url}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
