import Alex from "../../../assets/team6.png";
import Miriam from "../../../assets/team5.png";
import Phiona from "../../../assets/team4.png";
import Roland from "../../../assets/team3.png";
import Sharon from "../../../assets/team2.png";
import Alinda from "../../../assets/team.png";
import { Facebook, Linkedin, Twitter } from "react-feather";
import { Link } from "react-router-dom";
import { useState } from "react";

export const Team = () => {
  const [active, setActive] = useState(-1);

  const teamData = [
    {
      name: "Alex Shaka",
      title: "CEO and Founder",
      photo: Alex,
    },
    {
      name: "Miriam Akongo",
      title: "Team member",
      photo: Miriam,
    },
    {
      name: "Phiona Kemigisha",
      title: "Team member",
      photo: Phiona,
    },
    {
      name: "Roland Sankara",
      title: "Team member",
      photo: Roland,
    },
    {
      name: "Sharon Akol",
      title: "Team member",
      photo: Sharon,
    },
    {
      name: "Sharon Alinda",
      title: "Team member",
      photo: Alinda,
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
        {teamData.map((team, index) => {
          return (
            <div
              key={index}
              className="p-6 rounded shadow-lg bg-white dark:bg-slate-500"
            >
              <div
                className="relative h-96 bg-gradient-to-t from-black to-gray-500 rounded"
                onMouseEnter={() => setActive(index)}
                onMouseLeave={() => setActive(-1)}
              >
                <img
                  src={team.photo}
                  alt="man standing"
                  className={`rounded w-full h-full object-cover object-top
                  ${active === index ? "mix-blend-overlay" : ""}`}
                  loading="lazy"
                />
                <div
                  className={`absolute inset-x-0 bottom-0 h-20 w-full flex justify-between px-4 
                  items-center ${active === index ? "" : "hidden"}`}
                >
                  <div className="text-white">
                    <p className="font-extrabold text-lg uppercase">
                      {team.name}
                    </p>
                    <p className="text-sm">{team.title}</p>
                  </div>
                  <div className="flex space-x-2">
                    <Link to="/">
                      <div className="bg-white px-2 py-1 rounded-full text-black">
                        <Facebook className="w-4" />
                      </div>
                    </Link>
                    <Link to="/">
                      <div className="bg-white px-2 py-1 rounded-full text-black">
                        <Twitter className="w-4" />
                      </div>
                    </Link>
                    <Link to="/">
                      <div className="bg-white px-2 py-1 rounded-full text-black">
                        <Linkedin className="w-4" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
