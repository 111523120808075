import {
  About,
  Blog,
  Gallery,
  Landing,
  NotFound,
  WhyCodeImpact,
  BlogPost,
} from "pages";
import { Route, Routes } from "react-router-dom";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AppRoutes } from "routes";

function App() {
  return <AppRoutes />;
}

export default App;
