import React from "react";
import { MainLayout } from "component/layout/MainLayout";
import cohortHeader from "../../assets/cohortsHeader.svg";
import { PageHeader } from "component/layout/components/PageHeader";
import { Headings } from "component/Text/Headings";
import { Join } from "./components/Join";
import { Learn } from "./components/Learn";
import { Alumni } from "./components/Alumni";

export const Cohort = () => {
  return (
    <MainLayout page="cohort">
      <div className="page-header">
        <PageHeader image={cohortHeader} title="Cohort">
          <div className="space-y-8">
            <div className="font-bold dark:text-black space-y-6">
              <p>
                Become a{" "}
                <span className="text-red-500">learning Facilitator</span>{" "}
                today,
              </p>
              <p>At CodeImpact and</p>
              <p>
                Launch your <span className="text-red-500">cohort</span>
              </p>
              <p>In just a week</p>
            </div>
            <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
              Apply here
            </button>
          </div>
        </PageHeader>
      </div>
      <div className="px-8 lg:px-32 dark:text-white bg-components">
        <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
          <div className="space-y-10">
            <Headings>Cohorts Based Training</Headings>
            <p className="text-lg">
              Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade
              om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona
              geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill
              säga kvasilig för polyvis. Tins krogoras. Pes hyfegen. Regyr
              benysm. Diras tenalig. Sperad min. Regt odade. Nåkofingen menas
              men detälig eunade. Besm dogmafilm för att rek monotet begir.
              Dimäsosm binde. Nikun megapol.
            </p>
            <Join />
            <Learn />
            <Alumni />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
