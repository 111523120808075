export const PageHeader = ({ children, image, title }) => {
  return (
    <>
      <div className="pt-12 pb-0 space-y-12 2xl:w-[1280px] 2xl:mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="space-y-8 text-4xl md:text-5xl">{children}</div>
          <div className="">
            <img
              src={image}
              alt="..."
              className="max-h-96 mx-auto"
              loading="lazy"
            />
          </div>
        </div>
        {title ? (
          <div className="space-x-4 flex ">
            <div className="bg-black w-0.5 h-6"></div>
            <p className="font-black text-xl">{title}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
