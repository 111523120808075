import React from "react";
import LogoImage from "../../../assets/logo.png";

export const Logo = () => {
  return (
    <>
      <img
        src={LogoImage}
        alt=""
        className="h-8 md:h-10 w-auto my-auto"
        loading="lazy"
      />
    </>
  );
};
