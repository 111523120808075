import {
  Facebook,
  Linkedin,
  Mail,
  Phone,
  Twitter,
  Youtube,
} from "react-feather";

export const Contact = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div
          className="order-2 md:order-1 py-14 bg-slate-900 text-white flex flex-col items-center 
        justify-center space-y-8 text-center text-lg"
        >
          <p className="font-bold text-xl">Get in touch</p>
          <div>
            <p>help@codeimpact.com</p>
            <p>+256 711 456 771</p>
            <p>P.o.Box 6721, Kampala, UG</p>
            <p>Block 68, Level 1, Nakawa</p>
            <p>Kampala, uganda</p>
          </div>
          <div className="flex space-x-8">
            <a href="https://www.facebook.com/codeimpactug/">
              <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                <Facebook className="w-4" />
              </div>
            </a>
            <a href="https://twitter.com/CodeImpact_ug">
              <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                <Twitter className="w-4" />
              </div>
            </a>
            <a href="https://www.youtube.com/channel/UCxyFbQUHwsxF1vD2D7cO1sw">
              <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                <Youtube className="w-4" />
              </div>
            </a>
            <a href="/">
              <div
                className="bg-white px-2 py-1 rounded-full text-black hover:text-white 
              hover:bg-gray-700"
                title="alex.nkuzi@codeimpact.co"
              >
                <Mail className="w-4" />
              </div>
            </a>
            <a href="/">
              <div
                className="bg-white px-2 py-1 rounded-full text-black hover:text-white 
              hover:bg-gray-700"
                title="+256-772-810410"
              >
                <Phone className="w-4" />
              </div>
            </a>
            <a href="https://www.linkedin.com/company/codeimpactug/">
              <div className="bg-white px-2 py-1 rounded-full text-black hover:text-white hover:bg-gray-700">
                <Linkedin className="w-4" />
              </div>
            </a>
          </div>
        </div>
        <div className="order-1 md:order-2 text-black bg-white px-10 py-14 space-y-6 dark:bg-slate-500">
          <input
            type="text"
            placeholder="Your name"
            className="bg-gray-100 w-full shadow-inner p-2 rounded dark:bg-gray-300 focus:bg-white focus:border-2 focus:border-gray-600 focus:outline-none"
          />
          <input
            type="text"
            placeholder="Phone number"
            className="bg-gray-100 w-full shadow-inner p-2 rounded dark:bg-gray-300 focus:bg-white focus:border-2 focus:border-gray-600 focus:outline-none"
          />
          <textarea
            type="text"
            placeholder="Your message"
            align="top"
            className="bg-gray-100 w-full shadow-inner p-2 h-32 rounded flex focus:bg-white focus:border-2 focus:border-gray-600 focus:outline-none
            dark:bg-gray-300 align-baseline"
          ></textarea>

          <div className="flex flex-row-reverse">
            <button className="bg-slate-900 px-4 py-2 text-white font-bold text-lg rounded">
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
