import { ArrowRight } from "react-feather";

export const Join = () => {
  return (
    <div className="rounded p-4 px-6 space-y-4 flex bg-white shadow-lg dark:bg-slate-500 flex-col">
      <p className="font-bold text-lg">Join Our Next Cohort</p>
      <p className="text-lg">
        Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än
        laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen
        resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för
        polyvis. Tins krogoras. Pes hyfegen. Regyr benysm. Diras tenalig. Sperad
        min. Regt odade. Nåkofingen menas men detälig eunade. Besm dogmafilm för
        att rek monotet begir. Dimäsosm binde. Nikun megapol.
      </p>
      <button className="rounded text-white flex w-fit bg-slate-900 dark:bg-slate-300 ">
        <span className="py-2 px-4 font-bold dark:text-gray-800">
          Get Started
        </span>
        <div className="dark:bg-slate-400 bg-sky-900 rounded-r py-2 px-4">
          <ArrowRight />
        </div>
      </button>
    </div>
  );
};
