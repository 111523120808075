import {
  About,
  Blog,
  BlogPost,
  Cohort,
  Community,
  Gallery,
  Landing,
  NotFound,
  TechKids,
} from "pages";
import { useRoutes } from "react-router-dom";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Landing /> },
    { path: "/about", element: <About /> },
    { path: "/blog", element: <Blog /> },
    { path: "/blog/:slug", element: <BlogPost /> },
    { path: "/gallery", element: <Gallery /> },
    { path: "/cohort", element: <Cohort /> },
    { path: "/community", element: <Community /> },
    { path: "/tech-kids", element: <TechKids /> },
    { path: "/*", element: <NotFound /> },
  ];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
