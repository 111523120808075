import { Headings } from "component/Text/Headings";
import TestimonialImage from "../../../assets/team2.png";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
import { Card } from "component/Card/Card";

export const Testimonials = () => {
  //choose the screen size to determine number of slides displayed in carousel
  const [slides, isSlides] = useState(2);
  const handleResize = () => {
    if (window.innerWidth < 770) {
      return isSlides(1);
    } else {
      return isSlides(2);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  const testimonialList = [
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: TestimonialImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: TestimonialImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
    {
      name: "Thomas K",
      cohort: "Python Cohort",
      image_url: TestimonialImage,
      content:
        "Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill säga kvasilig för polyvis. Tins krogoras. Pes hyfegen",
    },
  ];

  return (
    <div className="space-y-10">
      <Headings>What our members say</Headings>
      <Swiper
        slidesPerView={slides}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {testimonialList.map((item, index) => (
          <SwiperSlide className="pb-16" key={index}>
            <Card
              name={item.name}
              content={item.content}
              post={item.cohort}
              img_url={item.image_url}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
