import React from "react";
import { MainLayout } from "component/layout/MainLayout";
import { PageHeader } from "component/layout/components/PageHeader";
import CommunityHeader from "../../assets/communityHeader.svg";
import { Headings } from "component/Text/Headings";
import { Activities } from "./components/Activities";
import { Testimonials } from "./components/Testimonials";
import { Achievements } from "./components/Achievement";

export const Community = () => {
  return (
    <MainLayout page="community">
      <div className="page-header">
        <PageHeader title="Community" image={CommunityHeader}>
          <div className="space-y-8">
            <div className="font-extrabold dark:text-black space-y-6">
              <p className="">CodeImpact</p>
              <p className="">Developer</p>
              <p className="">Community</p>
            </div>
            <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
              Join here
            </button>
          </div>
        </PageHeader>
      </div>
      <div className="bg-components">
        <div className="px-8 lg:px-32 dark:text-white">
          <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
            <div className="space-y-10 text-xl">
              <p>
                We are an online learning community aimed at empowering and
                nurturing young people that are passionate and excited about
                technology with technical, leadership, and soft skills that will
                expose them to possibilities of becoming world-class software
                developers and technology leaders on the African continent and
                beyond.
              </p>
              <Headings>Community Activities</Headings>
            </div>
          </div>
        </div>
        <div className="2xl:w-[1280px] 2xl:mx-auto">
          <Activities />
        </div>
        <div className="px-8 lg:px-32 dark:text-white">
          <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
            <div className="space-y-14">
              <Headings>Why CodeImpact Dev Community</Headings>
              <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                <div className="col-span-2 space-y-4">
                  <p className="text-xl">
                    We are an online learning community aimed at empowering and
                    nurturing young people that are passionate and excited about
                    technology with technical, leadership, and soft skills that
                    will expose them to possibilities of becoming world-class
                    software developers and technology leaders on the African
                    continent and beyond.
                  </p>
                  <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
                    Join here
                  </button>
                </div>
                <div className="col-span-3">
                  <div className="w-full h-full">
                    <iframe
                      className="w-full h-full"
                      src="https://www.youtube.com/embed/uhcA6K8rWp0"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <Testimonials />
            <Achievements />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
