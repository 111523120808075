import Codingallogo from "../../../assets/codingallogo.png";
import Artec from "../../../assets/artec.svg";
import Waape from "../../../assets/waape.svg";
import Uict from "../../../assets/uict.svg";
import T4s from "../../../assets/t4s.svg";
import WomenInnovate from "../../../assets/womenInnovate.png";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";

export const Partnership = () => {
  //choose the screen size to determine number of slides displayed in carousel
  const [slides, setSlides] = useState(2);

  const handleResize = () => {
    if (window.innerWidth < 770) {
      return setSlides(1);
    } else {
      return setSlides(2);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  const Companies = [
    {
      name: "Codingal Partnership",
      img_url: Codingallogo,
      content:
        "Our partners offer online code classes for kids to become innovators in the future. Register for free trial here",
      bg_color: "bg-white",
      dark_bg: "dark:bg-slate-500",
      url: "https://codingal.com",
    },
    {
      name: "Womenovate partnership",
      img_url: WomenInnovate,
      content:
        "We are committed to tech inclusion and our work with womenovate a nigerian based tech skilling startup gives us the opportunity to deliver our courses on their platform.",
      bg_color: "bg-[#0F172A]",
      dark_bg: "dark:bg-[#0F172A]",
      text_color: "white",
      url: "https://womenovate.com/",
    },
    {
      name: "Waape partnership",
      img_url: Waape,
      content:
        "Our cohort graduates through our Waape partnership are given opportunities for internships in tech startups both locally and remotely.",
      bg_color: "bg-[#231F20]",
      dark_bg: "dark:bg-[#231F20]",
      text_color: "white",
      url: "https://waape.ug/",
    },
    {
      name: "Artec partnership",
      img_url: Artec,
      content:
        "Artec a japanese robotics manufacturing company affords us the ability to run robotics clubs in schools. Register here to take part in our robotics competition in December",
      bg_color: "bg-[#4D4D4D]",
      dark_bg: "dark:bg-[#4D4D4D]",
      text_color: "white",
      url: "https://www.artec-kk.co.jp/en/",
    },
    {
      name: "UICT Innovation lab Partnership",
      img_url: Uict,
      content:
        "Our presence at the national innovation lab is our commitment to work with government to deliver last mile solutions and mentorship to foster innovation",
      bg_color: "bg-slate-900",
      dark_bg: "dark:bg-[#4D4D4D]",
      text_color: "white",
      url: "https://www.uict.ac.ug/",
    },
    {
      name: "T4S partnership",
      img_url: T4s,
      content:
        "We connect our tech mentorship graduates to work remotely for startups run by non technical founders.",
      bg_color: "bg-black",
      dark_bg: "dark:bg-black",
      text_color: "white",
    },
  ];

  return (
    <div className="h-full">
      <p className="font-bold text-2xl my-14 dark:text-white">
        Our Partnerships
      </p>
      <div className="">
        <Swiper
          slidesPerView={slides}
          spaceBetween={20}
          slidesPerGroup={slides}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {Companies.map((company, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="pb-14">
                  <a href={company.url} target="_blank" rel="noreferrer">
                    <div
                      className={`h-[22rem] p-8 shadow-lg ${company.bg_color} ${company.dark_bg} dark:text-white 
                    text-${company.text_color} space-y-4 rounded-lg`}
                    >
                      <img
                        src={company.img_url}
                        alt="..."
                        className="h-28 w-auto mx-auto"
                        loading="lazy"
                      />
                      <div className="text-base space-y-4">
                        <p className="font-bold text-left text-xl ">
                          {company.name}
                        </p>
                        <p className="text-justify text-md">
                          {company.content}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
