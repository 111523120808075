import { Headings } from "component/Text/Headings";
import { useState } from "react";
import { ArrowRight, Link, Linkedin, Phone, Twitter } from "react-feather";
import LearnImage from "../../../assets/learning.png";

export const Learn = () => {
  const [modalDetails, setModalDetails] = useState();
  const [showModal, setShowModal] = useState(false);

  const Facilitator = [
    {
      name: "Micheal Tusiime",
      image_url: LearnImage,
    },
    {
      name: "Peace Akello",
      image_url: LearnImage,
    },
    {
      name: "Rachael Ahereza",
      image_url: LearnImage,
    },
  ];

  return (
    <>
      <div className="space-y-4">
        <Headings>Learning Facilitators</Headings>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Facilitator.map((item, index) => (
            <div key={index} className="shadow-lg rounded-lg">
              <img
                src={item.image_url}
                alt="..."
                className="rounded mx-auto"
                loading="lazy"
              />
              <div className="-mt-8 opacity-90 bg-white p-4 rounded-lg">
                <div className="text-black">
                  <p className="font-bold text-xl">{item.name}</p>
                  <p className="text-md">Learning Facilitator</p>
                  <button
                    className="text-red-600 text-md mt-4 space-x-4 flex"
                    data-bs-toggle="mol"
                    data-bs-target="#facilitator"
                    onClick={() => {
                      setModalDetails(item);
                      setShowModal(true);
                    }}
                  >
                    <span>View Profile</span>
                    <ArrowRight className="w-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal ? (
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed
         inset-0 z-50 outline-none focus:outline-none shadow-lg mx-4"
        >
          <div className="relative w-auto mx-auto max-w-3xl">
            {/*content*/}
            <div className="shadow-lg relative rounded-md bg-white dark:text-white dark:bg-slate-700 text-black p-6">
              <div className="flex flex-col md:flex-row md:space-x-4 pt-2">
                <div className="md:w-1/2">
                  <img
                    src={modalDetails.image_url}
                    alt="..."
                    className="rounded-lg h-full mx-auto"
                    loading="lazy"
                  />
                </div>
                <div
                  className="space-y-4 -mt-20 bg-white p-2 rounded-lg flex flex-col justify-center 
                md:mt-0 md:w-3/6 dark:text-white dark:bg-slate-700"
                >
                  <div className="">
                    <Headings>{modalDetails.name}</Headings>
                    <p>Title</p>
                  </div>
                  <div>
                    <Headings>Course</Headings>
                    <p>The course details</p>
                  </div>
                  <div>
                    <Headings>Get in touch with him</Headings>
                    <div className="space-y-2 my-2">
                      <div className="space-x-2 flex items-center">
                        <div className="p-2 bg-gray-500 rounded-full text-white ">
                          <Phone className="w-5 h-5" />
                        </div>
                        <p>+256 934 0492 0203</p>
                      </div>
                      <div className="space-x-2 flex items-center">
                        <div className="p-2 bg-gray-500 rounded-full text-white">
                          <Twitter className="w-5 h-5" />
                        </div>
                        <p>Twitter name</p>
                      </div>
                      <div className="space-x-2 flex items-center">
                        <div className="p-2 bg-gray-500 rounded-full text-white">
                          <Linkedin className="w-5 h-5" />
                        </div>
                        <p>skfalskd</p>
                      </div>
                      <div className="space-x-2 flex items-center">
                        <div className="p-2 bg-gray-500 rounded-full text-white">
                          <Link className="w-5 h-5" />
                        </div>
                        <p>+256 934 0492 0203</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute top-0 right-0 p-2 md:p-6">
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="btn-close box-content w-4 h-4 p-1 dark:text-white dark:hover:text-white
                   text-black border-none rounded-none 
                opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black 
                hover:opacity-75 hover:no-underline"
                ></button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
