import PropTypes from "prop-types";

export const Spinner = () => {
  return (
    <>
      <div className="flex flex-row items-center h-[100vh]">
        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
      </div>
    </>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};
