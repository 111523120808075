import React from "react";
import { MainLayout } from "component/layout/MainLayout";
import techKidsHeader from "../../assets/techKidsHeader.svg";
import robotics from "../../assets/robotics.svg";
import { PageHeader } from "component/layout/components/PageHeader";
import { Headings } from "component/Text/Headings";
import { BeneficiariesStory } from "./components/BeneficiariesStory";

export const TechKids = () => {
  return (
    <MainLayout page="tech-kids">
      <div className="page-header">
        <PageHeader title="Tech Kids" image={techKidsHeader}>
          <p className="font-bold dark:text-black ">CodeImpact</p>
          <p className="font-bold dark:text-black">Kids In</p>
          <p className="font-bold dark:text-black">Tech</p>
          <div>
            <a
              href="https://forms.gle/H3sKLVQhPL3sVntMA"
              target="_blank"
              rel="noreferrer"
            >
              <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
                Join here
              </button>
            </a>
          </div>
        </PageHeader>
      </div>
      <div className="bg-components dark:text-white text-xl">
        <div className="px-8 lg:px-32 2xl:w-[1280px] 2xl:mx-auto pt-14 space-y-14">
          <div className="space-y-10">
            <Headings>Kids in Code</Headings>
            <p>
              Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade
              om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona
              geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill
              säga kvasilig för polyvis. Tins krogoras. Pes hyfegen. Regyr
              benysm. Diras tenalig. Sperad min. Regt odade. Nåkofingen menas
              men detälig eunade. Besm dogmafilm för att rek monotet begir.
              Dimäsosm binde. Nikun megapol.{" "}
            </p>
          </div>
          <div className="space-y-10">
            <Headings>Robotics</Headings>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <p className="">
                Lörem ipsum kovis megabös geonynar bengen antispev. Gypöck anade
                om än laliga, gos låsivis. Rehet vavill gången. Nymyskap prona
                geon. Sengen resocial nasat. Bekugt fysisk cd gigaliv det vill
                säga kvasilig för polyvis. Tins krogoras. Pes hyfegen. Regyr
                benysm. Diras tenalig. Sperad min. Regt odade. Nåkofingen menas
                men detälig eunade. Besm dogmafilm för att rek monotet begir.
                Dimäsosm binde. Nikun megapol.
              </p>
              <img
                src={robotics}
                alt="..."
                className="w-3/5 mx-auto"
                loading="lazy"
              />
            </div>
          </div>
          <div className="space-y-10">
            <Headings>What Our Program Beneficiaries have to say</Headings>
            <BeneficiariesStory />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
