import React, { useState } from "react";
import { MainLayout } from "component/layout/MainLayout";
import { PageHeader } from "component/layout/components/PageHeader";
import galleryHeader from "../../assets/galleryHeader.svg";
import galleryImage from "../../assets/team6.png";

export const Gallery = () => {
  const [activeFilter, setActiveFilter] = useState("Latest");
  const [active, setActive] = useState(-1);

  const gallery = [
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "large",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "large",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
    {
      name: "The basics of HTML code",
      category: "Social Media",
      image_url: galleryImage,
      type: "small",
    },
  ];

  return (
    <MainLayout page="gallery">
      <div className="page-header">
        <PageHeader image={galleryHeader} title="Gallery" />
      </div>
      <div className="px-8 lg:px-32 dark:text-white bg-components">
        <div className="py-14 space-y-14 2xl:w-[1280px] 2xl:mx-auto">
          {/* Filter section */}
          <div className="flex flex-col md:flex-row justify-between space-y-8">
            <div className="w-full md:w-7/12 flex space-x-4">
              <button
                className={
                  activeFilter === "Latest"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Latest")}
              >
                Latest Pictures
              </button>
              <button
                className={
                  activeFilter === "All"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("All")}
              >
                All
              </button>
              <button
                className={
                  activeFilter === "Social Media"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Social Media")}
              >
                Social Media
              </button>
              <button
                className={
                  activeFilter === "Archives"
                    ? "font-bold underline underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Archives")}
              >
                Archives
              </button>
            </div>
          </div>
          {/* images section */}
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {gallery.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      item.type === "large" ? "col-span-1 md:col-span-2" : ""
                    }
                  >
                    <div
                      className="relative h-80 bg-gradient-to-t from-black to-gray-500 rounded"
                      onMouseEnter={() => setActive(index)}
                      onMouseLeave={() => setActive(-1)}
                    >
                      <img
                        src={item.image_url}
                        alt="man standing"
                        className={`${
                          active === index ? "mix-blend-overlay" : ""
                        } w-full h-full object-cover object-top`}
                        loading="lazy"
                      />
                      <div
                        className={`${
                          active === index ? "" : "hidden"
                        } absolute inset-x-6 bottom-0 h-20 w-full`}
                      >
                        <div className="text-white">
                          <p className="text-sm">{item.category}</p>
                          <p className="font-bold text-lg">{item.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
