import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MainLayout } from "component/layout/MainLayout";

export const NotFound = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainLayout>
      <div className="p-20 px-40">
        <div className="text-lg font-bold dark:text-white">
          Sorry! The page you requested doesn't really exist.
        </div>
        <Link className="" to="/">
          Head back home.
        </Link>
      </div>
    </MainLayout>
  );
};
