import { useQuery } from "react-query";
import { posts } from "server/posts";

export const getPost = ({ slug }) => {
  return posts.find((post) => post.slug === slug);
};

export const usePost = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ["post", slug],
    queryFn: () => getPost({ slug }),
  });
};
