import React from "react";
import { BlogNavBar } from "./components/BlogNavbar";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/Navbar";
import { NewFooter } from "./components/NewFooter";

export const MainLayout = ({ page, children }) => {
  return (
    <div className="">
      <NavBar page={page} />
      {children}
      <NewFooter />
    </div>
  );
};
