import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import { UPLOADS_URL } from "config";
import { getPosts } from "../api/getPosts";

export const Blog = () => {
  const postsQuery = getPosts();

  //choose the screen size to determine number of slides displayed in carousel
  const [slides, isSlides] = useState(3);
  const handleResize = () => {
    if (window.innerWidth < 770) {
      return isSlides(1);
    }
    if (window.innerWidth < 1024 && window.innerWidth > 770) {
      return isSlides(2);
    } else {
      return isSlides(3);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <div className="dark:text-white">
        <p className="text-left font-bold text-2xl my-4">Dev Community Blog</p>
        <div className="w-full">
          <Swiper
            slidesPerView={slides}
            spaceBetween={20}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {postsQuery.map((post, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="pb-12">
                    <div
                      className="w-full opacity-90 h-[24rem] rounded border border-gray-400 bg-white 
                    dark:bg-slate-500 my-4 p-4 hover:shadow-xl hover:ease-in-out hover:duration-500"
                    >
                      <Link to={`/blog/${post.slug}`} className="">
                        <img
                          src={post.image_url}
                          alt="..."
                          className="w-full h-40"
                          loading="lazy"
                        />
                        <p className="p-2 md:p-4 h-32">{post.title}</p>
                        <div className="flex text-sm flex-wrap mt-4 md:flex-col lg:flex-row justify-between p-2 md:p-4">
                          <p className="text-red-500 dark:text-white">
                            @{post.author}
                          </p>
                          <p>{post.date}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
